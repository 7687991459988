import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const data = [
  "https://cdn.letsbld.com/letsbld/games/39/5dd83d25544f5_5dd83d255467a.jpg",
  "https://cdn.letsbld.com/letsbld/games/33/5dd83d2dc9214_5dd83d2dc93a6.jpg",
  "https://cdn.letsbld.com/letsbld/games/31/5dd9b63f59138_5dd9b63f592b1.jpg",
  "https://cdn.letsbld.com/letsbld/games/46/5dd83d8197efe_5dd83d81980b7.jpg",
  "https://cdn.letsbld.com/letsbld/games/43/5dd9b62f3d30f_5dd9b62f3d49a.jpg",
  "https://cdn.letsbld.com/letsbld/games/9/5dd83de6ed914_5dd83de6eda98.jpg",
];

const BuildStep2 = () => {
  let location = useLocation();

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column mb-5">
          <h5 className="d-inline-block">Game Details</h5>
          <div className="d-flex flex-row align-items-center">
            <div className="mr-3">
              <img src={data[location.state]} alt=".." />
            </div>
            <div clas="flex-1">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Perspiciatis aliquam ipsum error quae corrupti architecto culpa
              consequatur? Non beatae repellendus impedit sapiente officia!
              Earum, id! Quasi impedit doloribus dignissimos recusandae.
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex flex-column">
          <h5 className="d-inline-block">Pick a price</h5>
          <NavLink
            to={{
              pathname: "/build/step3",
              state: "R 10 000",
            }}
            className="btn btn-primary btn-border py-4 my-3"
          >
            R 10 000
          </NavLink>
          <NavLink
            to={{
              pathname: "/build/step3",
              state: "R 25 000",
            }}
            className="btn btn-primary btn-border py-4 my-3"
          >
            R 25 000
          </NavLink>
          <NavLink
            to={{
              pathname: "/build/step3",
              state: "R 40 000",
            }}
            className="btn btn-primary btn-border py-4 my-3"
          >
            R 40 000
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default BuildStep2;
