import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";

const Index = () => {
	const { cart, removeFromCart } = useContext(CartContext);

	if (!cart) return null;

	return (
		<>
			<div className="cart-wrapper">
				<div className="container">
					<div className="mt-3 mb-5 text-center">
						<h1>Cart</h1>
						{cart.length > 0 ? (
							cart.map((item, index) => (
								<div key={index}>
									<div
										className="d-flex flex-row align-items-center"
										style={{ border: "1px solid rgba(0,0,0,0.1)" }}
									>
										<div className="mr-3">
											<b>{index + 1}.</b>
										</div>
										<div className="d-flex flex-column flex-1 text-left">
											<div>ID: {item.id}</div>
											<div>Variant: {item.variant}</div>
											<div>Quantity: {item.quantity}</div>
										</div>
										<div className="d-flex flex-column text-right">
											<button className="btn btn-primary" onClick={() => removeFromCart(item.id)}>
												Remove
											</button>
										</div>
									</div>
								</div>
							))
						) : (
							<div>
								<b>Your cart is currently empty</b>
								<div>
									View all products <NavLink to="/products">here</NavLink>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Index;
