import React from "react";

import { NavLink } from "react-router-dom";

const Index = () => {
  return (
    <>
      <div
        className="position-relative overflow-hidden text-center bg-dark-grey text-light"
        style={{
          backgroundImage: "url('/assets/images/sp-bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="col-md-5 p-lg-5 mx-auto my-5">
          <h1 className="display-4 font-weight-normal">BUILD YOUR OWN</h1>
          <p className="lead font-weight-normal">Gaming PCs made simple.</p>
          <NavLink className="btn btn-primary d-inline-flex" to="/build">
            Get Started
          </NavLink>
        </div>
        <div className="product-device d-none d-md-block">
          <img src="/assets/images/pc.png" alt=".." />
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row  w-100">
        <div className="bg-light flex-1 pt-3 px-3 pt-md-5 px-md-5 text-center text-dark overflow-hidden">
          <div className="my-3 py-3">
            <h2 className="display-5">Starter PC</h2>
            <p className="lead">R 25000.00</p>
          </div>
          <div
            className="bg-light mx-auto"
            style={{
              width: "80%",
              height: "300px",
              borderRadius: "21px 21px 0 0",
            }}
          >
            <img
              src="https://cdn.letsbld.com/prebuilds/images_gallery_5f60d52393f4e.png"
              alt=".."
            />
          </div>
        </div>
        <div className="bg-dark-grey flex-1 pt-3 px-3 pt-md-5 px-md-5 text-center text-light overflow-hidden">
          <div className="my-3 p-3">
            <h2 className="display-5">Streaming PC</h2>
            <p className="lead">R 34000.00</p>
          </div>
          <div
            className="bg-dark-grey mx-auto"
            style={{
              width: "80%",
              height: "300px",
              borderRadius: "21px 21px 0 0",
            }}
          >
            <img
              src="https://cdn.letsbld.com/prebuilds/images_gallery_5f60d3b49393c.png"
              alt=".."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
