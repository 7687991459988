import React from "react";
import { Route, Switch } from "react-router";
import Build from "./Index";

const BuildRouter = () => {
  return (
    <Switch>
      <Route path="/build" component={Build} />
    </Switch>
  );
};

export default BuildRouter;
