import React from "react";
import { NavLink } from "react-router-dom";

const data = [
  "https://cdn.letsbld.com/letsbld/games/39/5dd83d25544f5_5dd83d255467a.jpg",
  "https://cdn.letsbld.com/letsbld/games/33/5dd83d2dc9214_5dd83d2dc93a6.jpg",
  "https://cdn.letsbld.com/letsbld/games/31/5dd9b63f59138_5dd9b63f592b1.jpg",
  "https://cdn.letsbld.com/letsbld/games/46/5dd83d8197efe_5dd83d81980b7.jpg",
  "https://cdn.letsbld.com/letsbld/games/43/5dd9b62f3d30f_5dd9b62f3d49a.jpg",
  "https://cdn.letsbld.com/letsbld/games/9/5dd83de6ed914_5dd83de6eda98.jpg",
];

const BuildStep1 = () => {
  return (
    <div>
      <h5>Pick a game</h5>

      <div className="row">
        {data.map((game, index) => (
          <div key={index} className="col-6 col-md-3 col-lg-2 mt-4">
            <NavLink
              to={{
                pathname: "/build/step2",
                state: index,
              }}
            >
              <img src={game} className="w-100" alt=".." />
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuildStep1;
