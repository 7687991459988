import React from "react";
import { Route, Switch } from "react-router";
import BuildStep1 from "./pages/Step1";
import BuildStep2 from "./pages/Step2";
import BuildStep3 from "./pages/Step3";

const Build = () => {
  return (
    <div className="container my-5">
      <Switch>
        <Route exact path="/build" component={BuildStep1} />
        <Route exact path="/build/step2" component={BuildStep2} />
        <Route exact path="/build/step3" component={BuildStep3} />
      </Switch>
    </div>
  );
};

export default Build;
