import React from "react";

const Shipping = () => {
  return (
    <>
      <div className="container my-5">
        <h3>Shipping</h3>
      </div>
    </>
  );
};

export default Shipping;
