import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const BuildStep3 = () => {
  let location = useLocation();
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column">
          <div className="d-flex flex-row align-items-center">
            <img src="/assets/images/pc.png" alt=".." className="img-fluid" />
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex flex-column">
          <div>
            <h3>NZXT H510</h3>
            <div class="d-flex flex-column">
              <div>
                <h5>case:</h5>
                <p>NZXT H510</p>
              </div>
              <div>
                <h5>cpu:</h5>
                <p>Intel Core i5-9400F 6-Core 2.9 GHz</p>
              </div>
              <div>
                <h5>gpu:</h5>
                <p>NVIDIA GeForce RTX 2070 SUPER - ZOTAC MINI</p>
              </div>
              <div>
                <h5>motherboards:</h5>
                <p>MSI Z390-A PRO</p>
              </div>
              <div>
                <h5>ram:</h5>
                <p>XPG GAMMIX D10 3000 MHz 16GB</p>
              </div>
              <div>
                <h5>storage:</h5>
                <p>WD Blue 3D NAND (250.0 GB)</p>
              </div>
              <h2>{location.state}</h2>
              <button className="btn btn-primary btn-border mt-3">
                Customize
              </button>
              <NavLink
                to={{
                  pathname: "/checkout",
                  state: location.state,
                }}
                className="btn btn-primary mt-3"
              >
                Checkout
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildStep3;
