import React, { createContext, useState } from "react";

export const CartContext = createContext(null);

const STORAGE_KEY = "cart";

const CartProvider = ({ children }) => {
	const [cart, setCart] = useState(JSON.parse(localStorage.getItem(STORAGE_KEY)) || []);

	const addToCart = (id, variant) => {
		var _cart = [...cart];

		var exists = cart.find((x) => x.id === id && x.variant === variant);

		if (exists) {
			var index = _cart.indexOf(exists);

			exists.quantity += 1;
			_cart[index] = exists;
		} else {
			exists = { id, variant, quantity: 1 };
			_cart.push(exists);
		}

		localStorage.setItem(STORAGE_KEY, JSON.stringify(_cart));

		setCart([..._cart]);
	};

	const removeFromCart = (id) => {
		var _cart = [...cart];

		var index = _cart.indexOf(_cart.find((x) => x.id === id));

		if (index !== -1) {
			_cart.splice(index, 1);

			localStorage.setItem(STORAGE_KEY, JSON.stringify([..._cart]));

			setCart([..._cart]);
		}
	};

	const clearCart = () => {
		localStorage.setItem(STORAGE_KEY, JSON.stringify([]));
		setCart([]);
	};

	return (
		<CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>{children}</CartContext.Provider>
	);
};

CartProvider.context = CartContext;

export default CartProvider;
