import React from "react";
import "../styles/css/style.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import CartProvider from "../contexts/CartContext";

import ScrollToTop from "./shared/ScrollToTop";

import TopBar from "./layout/TopBar";
import Home from "./home/Index";
import Footer from "./layout/Footer";

import Cart from "./cart/Index";
import BuildRouter from "./build/Router";
import CheckoutRouter from "./checkout/Router";

const App = () => {
  return (
    <CartProvider>
      <Router>
        <ScrollToTop />
        <div className="page-container">
          <TopBar />

          <div className="page-content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/cart" component={Cart} />

              <Route path="/checkout" component={CheckoutRouter} />
              <Route path="/build" component={BuildRouter} />
            </Switch>
          </div>

          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
};

export default App;
