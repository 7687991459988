import React from "react";

import { NavLink } from "react-router-dom";

const TopBar = () => {
  return (
    <>
      <div className="top-bar">
        <div className="top-bar__secondary container-fluid">
          <div className="d-flex flex-row align-items-center w-100">
            <div className="socials flex-1">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-square" aria-hidden="true"></i>
              </a>
              <a
                href="https://www.twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" aria-hidden="true"></i>
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram" aria-hidden="true"></i>
              </a>
              <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube" aria-hidden="true"></i>
              </a>
              <a
                href="https://www.twitch.tv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitch" aria-hidden="true"></i>
              </a>
            </div>
            <div>
              <NavLink to="/" className="mr-2">
                Login
              </NavLink>
              |
              <NavLink to="/" className="ml-2">
                Register
              </NavLink>
            </div>
          </div>
        </div>
        <nav className="top-bar__primary navbar navbar-expand-lg container-fluid">
          <NavLink className="navbar-brand" to="/">
            <img src="/assets/images/white-logo.png" alt="Logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <NavLink className="nav-link" to="/">
                  What is Mammoth?
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Starter PC
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Streaming PC
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Support
                </NavLink>
              </li>
            </ul>
            <NavLink className="btn btn-primary btn-border" to="/build">
              Get Started
            </NavLink>
          </div>
        </nav>
      </div>
    </>
  );
};

export default TopBar;
