import React from "react";
import { Route, Switch } from "react-router";
import Checkout from "./Index";
import Shipping from "./Shipping";

const Router = () => {
  return (
    <Switch>
      <Route exact path="/checkout" component={Checkout} />
      <Route exact path="/checkout/shipping" component={Shipping} />
    </Switch>
  );
};

export default Router;
