import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Checkout = () => {
  let location = useLocation();
  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col-12 col-md-6 mb-5">
            <h5>Payment Method</h5>
            <div className="d-flex flex-column mt-3">
              <div className="row">
                <div className="col-4">
                  <button className="btn btn-primary btn-icon btn-icon-left w-100">
                    <i className="fas fa-credit-card icon"></i>
                    Credit Card
                  </button>
                </div>
                <div className="col-4">
                  <button className="btn btn-primary btn-border btn-icon btn-icon-left w-100">
                    <i className="fas fa-exchange-alt icon"></i>EFT
                  </button>
                </div>
                <div className="col-4">
                  <button className="btn btn-primary btn-border btn-icon btn-icon-left w-100">
                    <i className="fab fa-paypal icon"></i>PayPal
                  </button>
                </div>
              </div>
              <form className="mt-3">
                <div className="form-group">
                  <label>Name On Card</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Card Number</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <label>Month</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label>Year</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label>CVV</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <h5>Order Summary</h5>
            <div className="d-flex flex-row w-100 mt-3">
              <div className="mr-3">
                <img src="/assets/images/pc.png" alt=".." width="100" />
              </div>
              <div className="flex-1">
                <p>
                  NZXT H510, Intel Core i5-9400F 6-Core 2.9 GHz, NVIDIA GeForce
                  RTX 2070 SUPER - ZOTAC MINI, MSI Z390-A PRO, XPG GAMMIX D10
                  3000 MHz 16GB, WD Blue 3D NAND (250.0 GB)
                </p>
              </div>
              <div className="ml-5">
                <h5>{location.state}</h5>
              </div>
            </div>

            <div
              className="d-flex flex-row w-100 mb-5 mt-3 pt-2"
              style={{ borderTop: "1px solid #6c757d" }}
            >
              <div className="flex-1"></div>
              <div className="ml-5">
                Subtotal (1 item): <b>{location.state}</b>
              </div>
            </div>
            <div className="d-flex justify-content-end w-100">
              <NavLink to="/checkout/shipping" className="btn btn-primary">
                Continue To Shipping
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
